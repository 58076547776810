<template>
  <v-card width="400" class="mx-auto mt-5">
    <v-card-title>
      <h1 class="display-1">Cambiar Contraseña</h1>
    </v-card-title>
    <v-card-text>
      <v-alert dense v-if="error" type="error"
        >No se pudo cambiar la contraseña. Compruebe los datos
        ingresados.</v-alert
      >
      <v-alert dense v-if="success" type="success"
        >Se cambió la contraseña exitosamente!</v-alert
      >

      <v-form @submit.prevent v-if="!success">
        <v-text-field
          label="Contraseña Actual"
          v-model="oldPassword"
          :type="showOldPassword ? 'text' : 'password'"
          prepend-icon="mdi-lock"
          :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showOldPassword = !showOldPassword"
          :rules="oldPassRules"
          required
        >
        </v-text-field>

        <v-text-field
          label="Nueva Contraseña"
          v-model="newPassword"
          :type="showNewPassword ? 'text' : 'password'"
          prepend-icon="mdi-lock"
          :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showNewPassword = !showNewPassword"
          :rules="newPassRules"
          required
        >
        </v-text-field>

        <v-text-field
          label="Confirmar Contraseña"
          v-model="confirmPassword"
          :type="showConfirmPassword ? 'text' : 'password'"
          prepend-icon="mdi-lock"
          :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showConfirmPassword = !showConfirmPassword"
          :rules="confirmPassRules"
          required
        >
        </v-text-field>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            color="primary"
            @click="changePassword"
            :loading="loading"
            ><v-icon left dark> mdi-check </v-icon>Aceptar</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import EmpleadosService from "@/services/EmpleadosService";
const zxcvbn = require("zxcvbn");

export default {
  name: "ChangePass",
  data() {
    return {
      showConfirmPassword: false,
      showNewPassword: false,
      showOldPassword: false,
      newPassword: "",
      confirmPassword: "",
      oldPassword: "",
      error: null,
      success: null,
      loading: false,
      oldPassRules: [(v) => !!v || "Debe ingresar la contraseña actual"],
      newPassRules: [
        (v) =>
          zxcvbn(v).score >= 3 ||
          "La contraseña no es segura. Intente mezclar letras, números y símbolos.",
      ],
      confirmPassRules: [
        (v) =>
          v === this.newPassword ||
          "Confirmar contraseña debe coincidir con nueva contraseña",
      ],
    };
  },
  methods: {
    changePassword() {
      this.loading = true;
      let credentials = {
        old_password: this.oldPassword,
        new_password: this.newPassword,
      };
      EmpleadosService.changePassword(credentials)
        .then((response) => {
          this.success = true;
          this.oldPassword = "";
          this.newPassword = "";
          this.confirmPassword = "";
          this.error = null;
        })
        .catch((error) => {
          this.loading = false;
          this.error = error;
        });
    },
  },
};
</script>

<style></style>
